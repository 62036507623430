<template>
  <button class="df ai-c jc-sb w-100p container-btn m-b-16" @click="$emit('handleEmitOpen', 'Cтатус1')">
    <span class="text-btn">
      {{ title }}
    </span>
    <i :class="icon" style="font-size: 16px" />
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Статус',
    },
    icon: {
      type: String,
      default: 'el-icon-arrow-right',
    },
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="sass" scoped>
.container-btn
  border: none
  background: white

.text-btn
  color: #303133
  font-size: 16px
  font-weight: 400
  font-family: Roboto, sans-serif
</style>
