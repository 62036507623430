var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-b-16" },
    [
      _c("chosen-filter-button", {
        attrs: { title: _vm.titleSection },
        on: { handleEmitOpen: _vm.openModal },
      }),
      _c(
        "div",
        [
          _vm.option.length > 1
            ? _c("button-modal-mobile", {
                attrs: { id: _vm.type },
                on: { clicked: _vm.handleClicked },
              })
            : _vm._e(),
          _vm._l(_vm.option, function (item) {
            return _c("button-modal-mobile", {
              key: item.value,
              attrs: { id: String(item.value), title: item.label },
              on: { clicked: _vm.handleClicked },
            })
          }),
        ],
        2
      ),
      _c("div", {
        staticStyle: { border: "1px solid #ebeef5", "margin-top": "18px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }