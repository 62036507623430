var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "df ai-c jc-sb w-100p container-btn m-b-16",
      on: {
        click: function ($event) {
          return _vm.$emit("handleEmitOpen", "Cтатус1")
        },
      },
    },
    [
      _c("span", { staticClass: "text-btn" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c("i", { class: _vm.icon, staticStyle: { "font-size": "16px" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }