var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "container-btn",
      class: { "delete-btn": !_vm.title },
      on: {
        click: function ($event) {
          return _vm.$emit("clicked", _vm.id)
        },
      },
    },
    [
      _c("span", { staticClass: "text-btn", class: { "m-r-3": _vm.title } }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("i", { staticClass: "el-icon-close" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }