<template>
  <div class="m-b-16">
    <chosen-filter-button :title="titleSection" @handleEmitOpen="openModal" />
    <div>
      <button-modal-mobile
        v-if="option.length > 1"
        :id="type"
        @clicked="handleClicked"
      />

      <button-modal-mobile
        v-for="item in option"
        :id="String(item.value)"
        :key="item.value"
        :title="item.label"
        @clicked="handleClicked"
      />
    </div>
    <div style="border: 1px solid #ebeef5; margin-top: 18px" />
  </div>
</template>
<script>
import ButtonModalMobile from '@/views/manager/components/mobile/components/ui/ButtonModalMobile'
import ChosenFilterButton from '@/views/manager/components/mobile/components/ui/ChosenFilterButton'

export default {
  components: { ChosenFilterButton, ButtonModalMobile },
  props: {
    option: {
      type: Array,
      default: () => [],
    },
    titleSection: {
      type: String,
      default: 'test title',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      status: null,
    }
  },
  methods: {
    openModal() {
      this.$emit('open', this.type)
    },
    handleClicked(filter) {
      this.$emit('clickedEmit', filter, this.type)
    },
  },
}
</script>

<style lang="sass" scoped></style>
