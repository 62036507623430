<template>
  <button class="container-btn" :class="{'delete-btn': !title}" @click="$emit('clicked', id)">
    <span class="text-btn" :class="{'m-r-3': title}">{{ title }}</span
    ><i class="el-icon-close" />
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'el-icon-close',
    },
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="sass" scoped>
.container-btn
  background: #d9ecff
  border: none
  padding: 10px 14px
  border-radius: 8px
  margin-right: 8px
  margin-bottom: 8px
.text-btn
  color: #303133
  font-size: 12px
  font-weight: 500
  font-family: Roboto, sans-serif
.delete-btn
  background: #EBEEF5
</style>
